export const showFilePicker = async () => {
  return await new Promise<File[]>((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files) {
        reject('No files selected');
      } else {
        resolve(Array.from(files));
      }
    };
    input.oncancel = () => reject('Canceled file picker');
    input.click();
  });
};
