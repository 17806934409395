import { useEffect, useRef } from 'react';

export function useKeepSelectedMenuItemInView(cursor: number) {
  const selectedMenuItemRef = useRef<HTMLButtonElement>(null);
  const menuScrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const selectedMenuRect =
      selectedMenuItemRef.current?.getBoundingClientRect();
    const addMenuRect = menuScrollContainerRef.current?.getBoundingClientRect();

    if (!selectedMenuRect || !addMenuRect) {
      return;
    }
    if (selectedMenuRect.top < addMenuRect.top) {
      selectedMenuItemRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
    if (selectedMenuRect.bottom > addMenuRect.bottom) {
      selectedMenuItemRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [cursor]);

  return {
    selectedMenuItemRef,
    menuScrollContainerRef,
  };
}
