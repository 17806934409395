import { Company, User } from '../../../types.ts';
import { useState } from 'react';
import FileFormattingMenu from '../../../design-system/organisms/FileFormattingMenu.tsx';
import {
  FileUploadErrorModal,
  FileUploadLoadingModal,
} from '../../../editor/domFacing/components/FileUploadModal.tsx';
import HoverNextToPoint from '../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import SelectionCommentWithActions from './comments/SelectionCommentWithActions.ts';
import { isSameSelection } from '../../../SelectionComment.ts';
import SelectionCommentsMenu from './comments/components/SelectionCommentsMenu.tsx';
import { FileBlock } from 'editor-content/FileBlock.js';
import { CommentContentNode } from 'editor-content/CommentContent.js';
import useApi from '../../../api/useApi.js';
import { uploadAndCreateFileBlock } from '../../../editor/addBlock/zeck/AddBlockFileUpload.js';

type FileFormattingExperienceProps = {
  block: FileBlock;
  getEl: () => HTMLElement | undefined;
  company: Pick<Company, 'id'>;
  onReplaceFile: (file: Pick<FileBlock, 'guid' | 'filename'>) => void;
  onDeleteFile: () => void;
  onAddSelectionComment: (
    blockId: string,
    commentText: CommentContentNode[],
  ) => Promise<void>;
  user: User;
  selectionComments: SelectionCommentWithActions[];
  zeckId: string;
  sectionId: string;
};

type BlockInteractiveRenderState =
  | { type: 'formatting' }
  | { type: 'commenting' }
  | { type: 'replacing' }
  | { type: 'replacing-error'; message: string };

const FileFormattingExperience: React.FC<FileFormattingExperienceProps> = ({
  block,
  getEl,
  company,
  onReplaceFile,
  onDeleteFile,
  onAddSelectionComment,
  user,
  selectionComments,
  zeckId,
  sectionId,
}) => {
  const { createFile, uploadFile } = useApi();
  const uploadFileShouldProbablyBeAbstracted = async (file: File) => {
    const { fileId, writeUrl } = await createFile({
      companyId: company.id,
      filename: file.name,
      contentType: file.type,
    });

    await uploadFile(writeUrl, file);

    return fileId;
  };
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>({ type: 'formatting' });

  return (
    <>
      {interactiveState.type === 'formatting' && (
        <HoverNextToPoint
          viewportPolicy="none"
          containerStyles={{ zIndex: 'initial' }}
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
        >
          <FileFormattingMenu
            {...{
              onClickReplace: async () => {
                setInteractiveState({ type: 'replacing' });
                const result = await uploadAndCreateFileBlock(
                  uploadFileShouldProbablyBeAbstracted,
                )();

                console.log('result');

                switch (result.type) {
                  case 'cancel':
                    setInteractiveState({ type: 'formatting' });
                    break;
                  case 'success':
                    setInteractiveState({ type: 'formatting' });
                    onReplaceFile({
                      guid: result.data.guid,
                      filename: result.data.filename,
                    });
                    break;
                  case 'error':
                    setInteractiveState({
                      type: 'replacing-error',
                      message: result.message,
                    });
                    break;
                }
              },
              onClickDelete: () => {
                onDeleteFile();
              },
              onClickComment: () => {
                setInteractiveState({ type: 'commenting' });
              },
            }}
          />
        </HoverNextToPoint>
      )}
      {interactiveState.type === 'commenting' && (
        <HoverNextToPoint
          usePortal
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height + 24,
            ];
          }}
        >
          <SelectionCommentsMenu
            autofocus
            user={user}
            comments={selectionComments.filter((selectionComment) =>
              isSameSelection(selectionComment, block),
            )}
            onPostComment={(content) =>
              onAddSelectionComment(block.id, content)
            }
            companyId={company.id}
            zeckId={zeckId}
            sectionId={sectionId}
          />
        </HoverNextToPoint>
      )}
      <FileUploadLoadingModal isOpen={interactiveState.type === 'replacing'} />;
      <FileUploadErrorModal
        message={
          interactiveState.type === 'replacing-error'
            ? interactiveState.message
            : ''
        }
        onRequestClose={() => setInteractiveState({ type: 'formatting' })}
        isOpen={interactiveState.type === 'replacing-error'}
      />
    </>
  );
};

export default FileFormattingExperience;
