import { IconName } from 'icons';
import React from 'react';

export type SimpleBlockItem<BlockType> = {
  type: 'block';
  createNewBlock: () => BlockType[];
} & BlockDisplayInfo;

export const isSimpleBlockItem = <BlockType>(
  blockConfig: AddBlockItem<BlockType>,
): blockConfig is SimpleBlockItem<BlockType> => blockConfig.type === 'block';

export type ComplexBlockItem<BlockType> = {
  type: 'complex-block';
  renderAdding: (props: {
    onAdd: (block: BlockType[]) => void;
    onCancel: () => void;
  }) => React.ReactNode;
} & BlockDisplayInfo;

export const isComplexBlockItem = <BlockType>(
  blockConfig: AddBlockItem<BlockType>,
): blockConfig is ComplexBlockItem<BlockType> =>
  blockConfig.type === 'complex-block';

export type AsyncBlockItem<BlockType> = {
  type: 'async-block';
  createNewBlock: () => Promise<
    | { type: 'success'; data: BlockType }
    | { type: 'error'; message: string }
    | { type: 'cancel' }
  >;
  renderLoading: () => React.ReactNode;
  renderError: (props: {
    message: string;
    onClose: () => void;
  }) => React.ReactNode;
} & BlockDisplayInfo;

export const isAsyncBlockItem = <BlockType>(
  blockConfig: AddBlockItem<BlockType>,
): blockConfig is AsyncBlockItem<BlockType> =>
  blockConfig.type === 'async-block';

type BlockDisplayInfo = {
  label: string;
  description: string;
  blockType: string;
  iconName: IconName;
};

export type SeparatorItem = { type: 'separator'; label: string };

export type AddBlockItem<BlockType> =
  | SimpleBlockItem<BlockType>
  | ComplexBlockItem<BlockType>
  | AsyncBlockItem<BlockType>
  | SeparatorItem;

export const isBlockConfigForType =
  (blockType: string) =>
  <BlockType>(blockConfig: AddBlockItem<BlockType>): boolean =>
    blockConfig.type !== 'separator' && blockType === blockConfig.blockType;
