import { makeMakeApiRequest } from '../makeMakeApiRequest.js';
import {
  User,
  Zeck,
  Section,
  UserLite,
  UserAndPermission,
  UserAndContributorPermission,
} from '../../types.js';
import hydrateZeck from './hydrateZeck.js';
import hydrateSection from './hydrateSection.js';
import pick from 'lodash/pick.js';
import dehydrateSection from './dehydrateSection.js';
import { DeletedSection } from '../../types/DeletedSection.js';
import HydratedSection from './types/HydratedSection.ts';
import { Block } from 'editor-content/Block.js';

export type UpdateConflictErrorResponse = {
  status: 409;
  body: {
    updatedBy: User | null;
  };
};

export function isUpdateConflictErrorResponse(
  obj: unknown,
): obj is UpdateConflictErrorResponse {
  if (
    (obj as UpdateConflictErrorResponse).status === 409 &&
    (obj as UpdateConflictErrorResponse).body?.updatedBy !== undefined
  ) {
    return true;
  }

  return false;
}

const createSectionApi = (
  makeLambdaApiRequest: ReturnType<typeof makeMakeApiRequest>,
  accessToken: string | null,
) => ({
  createSection: async (
    section: Pick<
      Section,
      'zeckId' | 'body' | 'title' | 'headline' | 'supplemental'
    >,
  ): Promise<HydratedSection> => {
    return hydrateSection(
      await makeLambdaApiRequest(`/zeck/${section.zeckId}/section`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          title: section.title,
          headline: section.headline,
          body: section.body,
          supplemental: section.supplemental,
        }),
      }),
    );
  },
  updateSection: async (
    section: Partial<Section> & Pick<Section, 'id'>,
  ): Promise<HydratedSection> => {
    return hydrateSection(
      await makeLambdaApiRequest(`/section/${section.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(
          pick(dehydrateSection(section), [
            'title',
            'headline',
            'body',
            'coverImageId',
            'coverImageEnabled',
            'supplemental',
            'version',
          ]),
        ),
      }),
    );
  },
  deleteSection: async (companyId: string, id: string): Promise<void> => {
    await makeLambdaApiRequest(`/company/${companyId}/section/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  fetchZeckForSection: async (id: string): Promise<Zeck> =>
    hydrateZeck(
      await makeLambdaApiRequest(`/section/${id}/zeck`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    ),
  restoreSection: async (sectionId: string): Promise<Omit<Section, 'body'>> => {
    return (
      await makeLambdaApiRequest(`/section/${sectionId}/restore`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).section as Section;
  },
  removeSectionFromTrash: async (
    zeckId: string,
    sectionId: string,
  ): Promise<void> => {
    await makeLambdaApiRequest(`/zeck/${zeckId}/section-trash/${sectionId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  fetchZeckTrashedSections: async (
    zeckId: string,
    cursor: string | null,
  ): Promise<{
    models: DeletedSection[];
    pagination: {
      cursor: string | null;
    };
  }> => {
    const maybeCursorParam = cursor ? `?cursor=${cursor}` : '';

    const { trashedSections, pagination } = await makeLambdaApiRequest(
      `/zeck/${zeckId}/section-trash${maybeCursorParam}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return { models: trashedSections, pagination };
  },

  duplicateContentToCompany: async (
    content: Block[],
    sourceCompanyId: string,
    destCompanyId: string,
  ): Promise<Block[]> => {
    const response = await makeLambdaApiRequest(
      `/duplicate-content-to-company`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          content,
          sourceCompanyId,
          destCompanyId,
        }),
      },
    );
    return response.content;
  },

  getSectionContributors: async (
    companyId: string,
    zeckId: string,
    sectionId: string,
  ): Promise<UserLite[]> => {
    const { contributors } = await makeLambdaApiRequest(
      `/company/${companyId}/zeck/${zeckId}/section/${sectionId}/contributors`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return contributors;
  },

  getSectionContributorPermissions: async (
    companyId: string,
    zeckId: string,
    sectionId: string,
    cursor?: string | null,
  ): Promise<{
    models: UserAndPermission[];
    pagination: { cursor: string | null };
  }> => {
    const maybeCursor = cursor ? `?cursor=${cursor}` : '';

    const { contributorPermissions, pagination } = await makeLambdaApiRequest(
      `/company/${companyId}/zeck/${zeckId}/section/${sectionId}/contributor-permissions${maybeCursor}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return { models: contributorPermissions, pagination };
  },

  updateSectionContributorPermission: async ({
    companyId,
    zeckId,
    sectionId,
    userId,
    enabled,
  }: {
    companyId: string;
    zeckId: string;
    sectionId: string;
    userId: string;
    enabled: boolean;
  }): Promise<UserAndContributorPermission[]> => {
    const { contributorPermissions } = await makeLambdaApiRequest(
      `/company/${companyId}/zeck/${zeckId}/section/${sectionId}/contributor-permissions`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ userId, enabled }),
      },
    );
    return contributorPermissions;
  },
});

export default createSectionApi;
