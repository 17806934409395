import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import useDocumentEventListener from '../../junkDrawer/useDocumentEventListener.ts';
import { ElementAndData } from '../../junkDrawer/useElementAndDataArray.js';
import { AddBlockItem } from './AddBlockConfiguration.js';
import AddBlockMenuInfoLabel from './AddBlockMenuInfoLabel.tsx';
import AddBlockMenuItem from './AddBlockMenuItem.js';
import AddBlockMenuSeparator from './AddBlockMenuSeparator.js';
import AddingBlockMenu from './AddingBlockMenu.js';
import { useKeepSelectedMenuItemInView } from './useKeepSelectedMenuItemInView.js';
import { assertUnreachable } from 'editor-content/Block.js';

type InfoLabelItem = {
  type: 'info';
  label: string;
};

type AddBlockOptions<BlockType> = InfoLabelItem | AddBlockItem<BlockType>;

export function filterAddMenuOptions<BlockType>(
  compactedOptions: AddBlockItem<BlockType>[],
  blockFilter: string,
): AddBlockOptions<BlockType>[] {
  if (!blockFilter || !blockFilter.length) {
    return compactedOptions;
  }

  const filterRegex = new RegExp(blockFilter, 'i');
  const filtered = compactedOptions.filter(
    (block) =>
      block && block.label.match(filterRegex) && block.type !== 'separator',
  );

  const noResultsFoundLabel: InfoLabelItem = {
    type: 'info',
    label: 'No results found',
  };
  return filtered.length ? filtered : [noResultsFoundLabel];
}

function useFilterAddMenuOptions<BlockType>(
  compactedOptions: AddBlockItem<BlockType>[],
  blockFilter: string,
  setCursor: (value: ((prevState: number) => number) | number) => void,
) {
  const [filteredAddMenuOptions, setFilteredAddMenuOptions] =
    useState<AddBlockOptions<BlockType>[]>(compactedOptions);

  useEffect(() => {
    const filteredOptions = filterAddMenuOptions(compactedOptions, blockFilter);
    if (filteredOptions.length === filteredAddMenuOptions.length) return;

    setFilteredAddMenuOptions(filteredOptions);
    setCursor(0);
  }, [
    compactedOptions,
    filteredAddMenuOptions,
    blockFilter,
    setFilteredAddMenuOptions,
    setCursor,
  ]);

  return filteredAddMenuOptions;
}

const AddingBlockMenuWithItems = <BlockType extends { id: string }>({
  targetBlockId,
  blocksWithEl,
  addBlockOptions,
  onPickBlockType,
  onCancel,
}: {
  targetBlockId: string;
  blocksWithEl: ElementAndData<BlockType>[];
  addBlockOptions: (AddBlockItem<BlockType> | false | null | undefined)[];
  onPickBlockType: (blockType: string) => void;
  onCancel: () => void;
}) => {
  const [cursor, setCursor] = useState<number>(0);
  const [blockFilter, setBlockFilter] = useState<string>('');
  const compactedOptions = compact(addBlockOptions);

  const { selectedMenuItemRef, menuScrollContainerRef } =
    useKeepSelectedMenuItemInView(cursor);

  const filteredAddMenuOptions = useFilterAddMenuOptions(
    compactedOptions,
    blockFilter,
    setCursor,
  );

  const activateBlockItem = (blockConfig: AddBlockOptions<BlockType>) => {
    switch (blockConfig.type) {
      case 'info':
      case 'separator':
        return;
      case 'block':
      case 'complex-block':
      case 'async-block':
        return onPickBlockType(blockConfig.blockType);
    }
  };

  // addable blocks are the blocks that are displayed in the menu, built from the filtered blocks
  const addableBlocks = filteredAddMenuOptions.map((value, index) => {
    const selected = cursor == index;

    switch (value.type) {
      case 'info':
        return <AddBlockMenuInfoLabel key={value.label} label={value.label} />;
      case 'separator':
        return (
          <AddBlockMenuSeparator key={value.label}>
            {value.label}
          </AddBlockMenuSeparator>
        );

      case 'block':
      case 'complex-block':
      case 'async-block':
        return (
          <AddBlockMenuItem
            key={value.label}
            data-testid={`add-block-menu-item-${value.blockType}`}
            iconName={value.iconName}
            onClick={() => activateBlockItem(value)}
            label={value.label}
            description={value.description}
            selected={selected}
            selectedMenuRef={selected ? selectedMenuItemRef : undefined}
          />
        );
    }

    assertUnreachable(value);
  });

  useDocumentEventListener(
    'keydown',
    (e) => {
      const option = filteredAddMenuOptions[cursor];
      const isSkipBlockItem = (cursor: number): boolean => {
        const option = filteredAddMenuOptions[cursor];
        return !!option && option.type === 'separator';
      };
      switch (e.key) {
        case 'ArrowDown':
          setCursor((cursor) => {
            if (cursor === filteredAddMenuOptions.length - 1) {
              return 0;
            }
            return isSkipBlockItem(cursor + 1) ? cursor + 2 : cursor + 1;
          });
          e.stopPropagation();
          e.preventDefault();
          return;
        case 'ArrowUp':
          setCursor((cursor) => {
            if (cursor === 0) {
              return filteredAddMenuOptions.length - 1;
            }

            return isSkipBlockItem(cursor - 1) ? cursor - 2 : cursor - 1;
          });
          e.preventDefault();
          e.stopPropagation();
          return;
        case 'Enter':
          option && activateBlockItem(option);
          e.preventDefault();
          e.stopPropagation();
          return;
        case 'Escape': // all other are keys cause an onCancel
          onCancel();
          e.stopPropagation();
          return;
        case 'Shift':
        case 'Meta':
        case 'Alt':
        case 'Control':
          return;
        case ' ':
          // if the key would result in a filter with a double space, cancel
          if (blockFilter[blockFilter.length - 1] === ' ') {
            onCancel();
            return;
          }
          setBlockFilter(blockFilter.concat(e.key.toLowerCase()));
          return;

        case 'Backspace':
          if (blockFilter.length) {
            setBlockFilter(blockFilter.slice(0, -1));
          } else {
            onCancel();
          }
          return;
      }

      // if key is alphanumeric, add it to the filter
      if (e.key.match(/^\w$/)) {
        setBlockFilter(blockFilter.concat(e.key.toLowerCase()));
        return;
      }

      onCancel();
    },
    [cursor, addBlockOptions, onPickBlockType, onCancel],
    true, // capture
  );

  return (
    <AddingBlockMenu
      addMenuRef={menuScrollContainerRef}
      blocksWithEl={blocksWithEl}
      targetBlockId={targetBlockId}
      onCancel={onCancel}
      addableBlocks={addableBlocks}
    />
  );
};

export default AddingBlockMenuWithItems;
